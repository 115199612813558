<template>
  <vs-popup
    :title="$t('ranqueamento-geral')"
    class="mb-4"
    :active.sync="showRanking"
  >
    <ranking-table :service="service" :id="id"/>
  </vs-popup>
</template>

<script>
import RankingTable from './RankingTable.vue'

export default {
  components: { RankingTable },
  props: {
    id: {
      type: Number,
      default: null
    },
    showRanking: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    service: null,
  }),
  watch: {
    showRanking(val) {
      if (val === false) {
        this.$emit('closed')
      }
    }
  },
  methods: {
  }
}
</script>