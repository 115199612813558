var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.id
    ? _c("Grid", {
        ref: "questionnaireRankingGrid",
        attrs: {
          noDataText: "Não há ranking disponível ainda.",
          service: _vm.service,
          hide_searchbar: true,
          route_name: "questionnaire_ranking",
          route_grid_path: "/" + _vm.id + "/ranking",
          hide_actions: true,
          perPage: 11,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "gridThead",
              fn: function () {
                return [
                  _c(
                    "vs-th",
                    { key: "position", attrs: { "sort-key": "position" } },
                    [_vm._v(" Colocação ")]
                  ),
                  _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                    _vm._v(" Nome "),
                  ]),
                  _c(
                    "vs-th",
                    { key: "score", attrs: { "sort-key": "score" } },
                    [_vm._v(" Pontuação ")]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "gridData",
              fn: function (props) {
                return [
                  _vm.showDotsPrepend(props) ? _c("table-row-more") : _vm._e(),
                  _vm._l(props.data, function (tr, i) {
                    return _c(
                      "vs-tr",
                      { key: props.data[i], class: _vm.rowStyle(tr) },
                      [
                        _c("vs-td", { key: "position" }, [
                          _c("label", [_vm._v(_vm._s(tr.position) + "º")]),
                        ]),
                        _c("vs-td", { key: "name" }, [
                          _c("label", [_vm._v(_vm._s(tr.name))]),
                        ]),
                        _c("vs-td", { key: "score" }, [
                          _c("label", [_vm._v(_vm._s(tr.score))]),
                        ]),
                      ],
                      1
                    )
                  }),
                  _vm.showDotsAppend(props) ? _c("table-row-more") : _vm._e(),
                ]
              },
            },
          ],
          null,
          false,
          2925958202
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }