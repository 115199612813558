<template>
  <vs-tr>
    <vs-td key="position">
      <div class="mx-auto">
        <feather-icon
          svgClasses="w-4 h-4"
          type="filled"
          icon="MoreVerticalIcon">
        </feather-icon>
      </div>
    </vs-td>
    <vs-td key="name">
      <div class="mx-auto">
        <feather-icon
          svgClasses="w-4 h-4"
          type="filled"
          icon="MoreVerticalIcon">
        </feather-icon>
      </div>
    </vs-td>
    <vs-td key="score">
      <div class="mx-auto">
        <feather-icon
          svgClasses="w-4 h-4"
          type="filled"
          icon="MoreVerticalIcon">
        </feather-icon>
      </div>
    </vs-td>
  </vs-tr>
</template>

<script>
export default {
  
}
</script>