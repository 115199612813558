<template>
  <Grid
    v-if="id"
    ref="questionnaireRankingGrid"
    noDataText="Não há ranking disponível ainda."
    :service="service"
    :hide_searchbar="true"
    route_name="questionnaire_ranking"
    :route_grid_path="`/${id}/ranking`"
    :hide_actions="true"
    :perPage="11"
  >
    <template v-slot:gridThead>
      <vs-th
        key="position"
        sort-key="position"
      >
        Colocação
      </vs-th>

      <vs-th
        key="name"
        sort-key="name"
      >
        Nome
      </vs-th>

      <vs-th
        key="score"
        sort-key="score"
      >
        Pontuação
      </vs-th>

    </template>
    <template v-slot:gridData="props">
      <table-row-more v-if="showDotsPrepend(props)"/>
      <vs-tr :class="rowStyle(tr)" :key="props.data[i]" v-for="(tr, i) in props.data">
        <vs-td key="position">
          <label>{{tr.position}}º</label>
        </vs-td>
        <vs-td key="name">
          <label>{{tr.name}}</label>
        </vs-td>
        <vs-td key="score">
          <label>{{tr.score}}</label>
        </vs-td>
      </vs-tr>
      <table-row-more v-if="showDotsAppend(props)"/>
    </template>
  </Grid>
</template>

<script>
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import TableRowMore from '@/components/TableRowMore.vue'
import FeatherIcon from '@/components/FeatherIcon.vue'
export default {
  components: {
    FeatherIcon,
    TableRowMore
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    service: {
      type: ContentQuestionnaireService,
      default: null
    }
  },
  data: () => {
    return {
      service: null,
    }
  },
  methods: {
    showDotsPrepend(props) {
      const first = props.data[0]
      return (props && props.data && props.data.length > 0 && first.position !== 1)
    },
    showDotsAppend(props) {
      const last = props.data[props.data.length - 1]
      return (props && props.data && props.data.length > 0 && last.position !== last.last)
    },
    rowStyle(row) {
      let base = ''
      if (row.self) {
        base = 'border border-solid border-l-0 border-r-0 border-primary font-bold text-primary'
      }

      // switch (row.position) {
      // case 1:
      //   base = `${base} text-warning`
      //   break
      // case 2:
      //   base = `${base} text-primary`
      //   break
      // case 3:
      //   base = `${base} text-primary`
      //   break
      // }

      return base
    }
  },
  created() {
    if (this.service === null) {
      this.service = ContentQuestionnaireService.build(this.$vs)
    }
  }
}
</script>