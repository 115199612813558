var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-popup",
    {
      staticClass: "mb-4",
      attrs: { title: _vm.$t("ranqueamento-geral"), active: _vm.showRanking },
      on: {
        "update:active": function ($event) {
          _vm.showRanking = $event
        },
      },
    },
    [_c("ranking-table", { attrs: { service: _vm.service, id: _vm.id } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }